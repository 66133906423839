export default {
    GeometryType: {
        0: "Point",
        1: "Line",
        2: "Polygon"
    },

    Basemaps: {
        0: "OpenStreetMap Carto",
        1: "Esri World Imagery",
        2: "None"
    },

    ExportFormats: {
        0: "JSON",
        1: "KML",
        2: "Shapefile",
        3: "CSV",
        4: "CAD"
    }
}