import { Popup, useMap, useMapEvents } from "react-leaflet";
import { divIcon } from "leaflet";
import Form from 'react-bootstrap/Form'
import { useEffect, useState } from "react";
import stringUtilities from "../../../utilities/stringUtilities";
import { Button, Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import ConfigurationDialog from "./_configurationDialog";
import WorkflowSelect from "../../form/workflowSelect";
import postal from 'postal';
import RotationSlider from "../../form/rotationSlider";
import SimpleBar from "simplebar-react";
import arrayUtilities from "../../../utilities/arrayUtilities";
import FontSizeSelect from "../../form/fontSizeSelect";
import WidthSlider from "../../form/widthSlider";
import ColorPicker from "../../form/colorPicker";
import cloneDeep from 'lodash.clonedeep';
import LimitSelect from "../../form/limitSelect";
import SnapToPoint from "../snapToPoint";
import SnappableMarker from "../snappableMarker";
import TextMarkerMarker from "./_textMarkerMarker";

export default function TextMarker(props) {

    const [isDrawing, setIsDrawing] = useState(props.isDrawing);
    const [isEditing, setIsEditing] = useState(props.isEditing);
    const [isVisible, setIsVisible] = useState(false);
    const [doSnapToPoint, setDoSnapToPoint] = useState(null);

    const map = useMap();
    const snappingTolerance = Math.max(props.snappingTolerance, 0.5);

    const [popupOpen, setPopupOpen] = useState(false);

    const [coords, setCoords] = useState(null);

    const [hasMany, setHasMany] = useState(false);
    const [rotate, setRotate] = useState(null);
    const [width, setWidth] = useState(null);
    const [fontsize, setFontsize] = useState(null);
    const [color, setColor] = useState(null);
    const [text, setText] = useState(null);
    const [workflowId, setWorkflowId] = useState(null);
    const [limitId, setLimitId] = useState(null);

    const reset = function () {

        if (!arrayUtilities.isNullOrEmpty(props.textMarker?.coords)) {
            setCoords(props.textMarker.coords);
        } else if (props.textMarker?.lat && props.textMarker?.lng) {
            setCoords([{
                lat: props.textMarker?.lat,
                lng: props.textMarker?.lng
            }]);
        } else {
            setCoords([]);
        }

        setRotate(props.textMarker?.rotate ?? 0);
        setWidth(props.textMarker?.width ?? 0);
        setFontsize(props.textMarker?.fontsize ?? "font-smaller");
        setColor(props.textMarker?.color ?? "#000000");
        setText(props.textMarker?.text ?? "");
        setWorkflowId(props.textMarker?.workflowId ?? "");
        setLimitId(props.textMarker?.limitId ?? props.activeLimit ?? null);
    }

    const onDelete = function () {

        if (props.onDelete) {
            props.onDelete(props.index);
        }
    }

    const onCancel = function () {

        if (props.onCancel) {
            props.onCancel();
        }

        setIsDrawing(false);
        setIsEditing(false);
        reset();
    }

    const onSave = function () {

        if (props.onSave) {

            let textMarker = {
                coords,
                text,
                rotate,
                width,
                fontsize,
                color,
                workflowId,
                limitId
            }
            props.onSave(textMarker, props.index);
        }

        setIsDrawing(props.isDrawing)
        setIsEditing(props.isEditing);
        reset();
    }

    const onSnappableMarkerDragEnd = function (latlng, index, workflowId) {

        let coordsCopy = cloneDeep(coords);
        coordsCopy.splice(index, 1, latlng);
        setCoords(coordsCopy);

        if (!stringUtilities.isNullOrEmpty(workflowId)) {
            setWorkflowId(workflowId);
        }
    }

    const onAddPoint = function (latlng, workflowId) {

        setDoSnapToPoint(null);

        let newcoords = arrayUtilities.newOrConcat(coords, latlng);
        setCoords(newcoords);

        if (!stringUtilities.isNullOrEmpty(workflowId)) {
            setWorkflowId(workflowId);
        }
    }

    const deleteCoord = function (index) {

        map.closePopup();

        let copy = cloneDeep(coords);
        copy.splice(index, 1);
        setCoords(copy);
    }

    useMapEvents({
        click: (e) => {

            if (!popupOpen && (isDrawing || isEditing)) {
                setDoSnapToPoint(e.latlng);
            }
        },
        popupopen: (e) => {
            setPopupOpen(true);
        },
        popupclose: (e) => {
            setPopupOpen(false);
        }
    })

    useEffect(() => {
        setHasMany(!arrayUtilities.isNullOrEmpty(coords) && coords.length > 1);
    }, [coords])

    useEffect(() => {

        if (isEditing) {
            postal.publish({
                channel: "map",
                topic: "isDrawingOrEditing",
                data: { value: true }
            })
        }

    }, [isEditing])

    useEffect(() => {
        let pointsLinesIsDefault = arrayUtilities.isNullOrEmpty(props.workflowPoints) && arrayUtilities.isNullOrEmpty(props.workflowLines) && arrayUtilities.isNullOrEmpty(props.workflowPointsLines);
        let workflowIsVisible = stringUtilities.isNullOrEmpty(workflowId) || pointsLinesIsDefault || props.workflowPoints.includes(workflowId) || props.workflowLines.includes(workflowId) || props.workflowPointsLines.includes(workflowId);

        setIsVisible(isDrawing || isEditing || workflowIsVisible);
    }, [isDrawing, isEditing, workflowId, props.workflowLines, props.workflowPoints, props.workflowPointsLines])

    useEffect(() => {
        reset();       
    }, []);

    const getIcon = function(id) {
        return divIcon({
            html: `<div id="${id}" class="text-overlay ${!stringUtilities.isNullOrEmpty(fontsize) ? fontsize : ""} ${(width && width > 0) ? "has-width" : ""}" style="${(width && width > 0) ? `width: ${width}px;` : ""} transform: rotate(${rotate ?? 0}deg); ${!stringUtilities.isNullOrEmpty(color) ? `color: ${color};` : ""}">${(stringUtilities.isNullOrEmpty(text) ? "Add Note" : text)}</div>`,
            className: "marker-icon-text-overlay",
            iconAnchor: [0, 0]
        });
    }    

    let title = hasMany ? "Notes" : "Note";
    let deleteTitle = hasMany ? "Delete notes" : "Delete note";
    let deleteContent = hasMany ? "Are you sure you want to permanently delete these notes?" : "Are you sure you want to permanently delete this note?";

    return <>

        {!arrayUtilities.isNullOrEmpty(coords) && isVisible &&
            <>
                {coords.map((coord, index) => {

                    let key = `text-marker-${props.index}-${index}`;
                    let icon = getIcon(key);

                    return <>
                        {(isDrawing || isEditing) && <SnappableMarker icon={icon}
                            key={key}
                            index={index}
                            position={[coord.lat, coord.lng]}
                            onDragend={(e, index, workflowId) => onSnappableMarkerDragEnd(e.latlng, index, workflowId)}
                            snappingTolerance={snappingTolerance}
                            sketch={props.sketch}
                            points={props.points}
                            lines={props.lines}
                            workflowLines={props.workflowLines}
                            workflowPoints={props.workflowPoints}
                            workflowPointsLines={props.workflowPointsLines} >
                            <Popup>
                                <div className='card border-0'>
                                    <div className='card-body position-relative d-flex justify-content-center'>
                                        <Button size="sm" variant='danger' onClick={() => deleteCoord(index)}>Delete Point</Button>
                                    </div>
                                </div>
                            </Popup>
                        </SnappableMarker>}

                        {!isDrawing && !isEditing && <TextMarkerMarker
                            key={key}
                            id={key}
                            icon={icon}
                            index={index}
                            coord={coord}
                            limitId={limitId}
                            workflowId={workflowId}
                            workflows={props.workflows}
                            sketch={props.sketch}
                            title={title}
                            deleteTitle={deleteTitle}
                            deleteContent={deleteContent}
                            onDelete={onDelete}
                            onEdit={() => setIsEditing(true)}
                        />}
                    </>
                })}
            </>
        }


        {doSnapToPoint !== null &&
            <SnapToPoint
                latlng={doSnapToPoint}
                icon={getIcon(`text-marker-${props.index}-snap-to-point`)}
                snappingTolerance={snappingTolerance}
                sketch={props.sketch}
                points={props.points}
                lines={props.lines}
                onSnappedPointSelected={onAddPoint}
                onCancel={() => setDoSnapToPoint(null)}
                workflowLines={props.workflowLines}
                workflowPoints={props.workflowPoints}
                workflowPointsLines={props.workflowPointsLines} />
        }

        <ConfigurationDialog show={isDrawing || isEditing} title={`${isDrawing ? "Add" : "Edit"} ${title}`} onCancel={onCancel} onSave={onSave}>
            <Form.Group controlId='input-small' className='mb-3'>
                <Form.Label className='text-body fs-sm me-2 pe-1 text-nowrap'>
                    Text
                </Form.Label>
                <InputGroup className="mb-3" size="sm">
                    <Form.Control size='sm' value={text} onChange={(e) => setText(e.target.value)} />
                    <DropdownButton variant="outline-secondary" align="end" size="sm">
                        <SimpleBar autoHide={false} className='simplebar-no-autohide pe-3' style={{ maxHeight: '200px' }}>
                            
                            {/* Start PVS/GTel items (we need to make these configurable)  */}
                            <Dropdown.Item href="#" onClick={() => setText("BL")}>Building Line: BL</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("CL")}>Curb Line: CL</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("DW")}>Driveway: DW</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("FL")}>Fence Line: FL</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("RE")}>Road Edge: RE</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("SW")}>Sidewalk: SW</Dropdown.Item>
                            {/* End PVS/GTel items --------------------------------------  */}

                            <Dropdown.Item href="#" onClick={() => setText("B")}>Cable: B</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("C")}>Conduit: C</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("F/O")}>Fibre: F/O</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("BSW")}>Service Wire: BSW</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("GM")}>Gas Main: GM</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("GS")}>Gas Service: GS</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("HP")}>Hydro One Primary: HP</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("H")}>Hydro Trench: H</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("HS")}>Secondary: HS</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("SL")}>Streetlight Cable: SL</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("SA")}>Sani Mainline: SA</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("SA")}>Force Main: SA</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("ST")}>Storm Mainline: ST</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("TC")}>Traffic Cable: TC</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("HS")}>Electrical Feed: HS</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("IC")}>Interconnect: IC</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("LOOP")}>Signal Loop: LOOP</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("WM")}>Water Main: WM</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={() => setText("WS")}>Water Service: WS</Dropdown.Item>
                        </SimpleBar>
                    </DropdownButton>
                </InputGroup>
            </Form.Group>

            <FontSizeSelect value={fontsize} onChange={setFontsize} />
            <ColorPicker value={color} onChange={setColor} />
            <RotationSlider value={rotate} onChange={setRotate} />
            <WidthSlider value={width} onChange={setWidth} />

            <WorkflowSelect value={workflowId} onChange={setWorkflowId} workflows={props.workflows} />
            <LimitSelect value={limitId} onChange={setLimitId} limits={props.sketch?.limits} />
        </ConfigurationDialog>
    </>
}